
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Bio, Gallerie } from '../../components';
import { SectionAlternate } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    padding: theme.spacing(2),
    //width: 200,
    //height: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  nom: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  titre: {
    paddingTop: 5,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  bio: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  cadre_global: {
    padding: 50,
  },
}));

const Artiste = props => {
  const { bio, image, oeuvres_md_edges, oeuvres_jpg_edges, className, ...rest } = props;
  const classes = useStyles();
  return (


    <div className={classes.cadre_global}>
      <Bio data={bio} image={image} />

      <Gallerie oeuvres_md_edges={oeuvres_md_edges} oeuvres_jpg_edges={oeuvres_jpg_edges} />

    </div>
  );
};

export default Artiste;