import React from "react"
import { graphql } from "gatsby"
import { Artiste } from './components';
//import { Artiste} from '../../views';
import { makeStyles } from '@material-ui/core';
import Ei from 'layouts/Ei';
import WithLayout from 'WithLayout';
import { Helmet } from "react-helmet"



const useStyles = makeStyles(theme => ({
  sectionTrucking: {
    maxWidth: '100%',
    paddingRight: 0,
    paddingLeft: 0,
  },
  featuresSection: {
    background: 'url(https://assets.maccarianagency.com/the-front/illustrations/patterns-bg.svg) no-repeat center',
    backgroundSize: 'contain',
  },

  gallerie: {
    paddingTop: 100,
  },

  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  reviewSection: {
    background: theme.palette.primary.dark,
  },
  aboutSection: {
    background: '#0c133e',
  },
}));


export default function ArtisteTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.bio.frontmatter.nom}</title>
        <link rel="canonical" href={"https://elseink.com" + data.bio.frontmatter.slug} />
      </Helmet>
      <WithLayout
        component={() => Artiste({
          bio: data.bio.frontmatter,
          image: data.portrait.childImageSharp.fluid,
          oeuvres_md_edges: data.oeuvres_md.edges,
          oeuvres_jpg_edges: data.oeuvres_jpg.edges
        })}
        layout={Ei}
      />
    </div>



  )
}

export const pageQuery = graphql`
  query($regex_image: String!, $regex_md: String!, $regex_oeuvres_jpg: String!, $regex_artiste_folder: String!) {
    bio:markdownRemark(fileAbsolutePath: {regex: $regex_md}) {
      frontmatter {
        nom
        titre
        bio
        slug
        expositions
      }
    }
    portrait:file(relativePath: {regex: $regex_image}) {
      childImageSharp {
        fluid(jpegQuality: 90) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    oeuvres_jpg:allFile(filter: {absolutePath: {regex: $regex_oeuvres_jpg}}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              presentationHeight
            }
          }
          absolutePath
        }
      }
    }
    oeuvres_md:allMarkdownRemark(filter: {frontmatter: {type: {regex: "/peinture/"}}, fileAbsolutePath: {regex: $regex_artiste_folder}}) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            titre
            artiste
            prix
            slug
          }
        }
      }
    }
  }
`