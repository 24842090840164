
import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Img from "gatsby-image" // to take image data and render it


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {

    [theme.breakpoints.up('md')]: {
      marginRight: 50,
      marginLeft: theme.spacing(2),
    },

    //width: 200,
    //height: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  nom: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  titre: {
    paddingTop: 5,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  bio: {
    paddingTop: 45,
    paddingRight: 5,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  exposition: {
    paddingTop: theme.spacing(5),
    paddingRight: 5,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
    textAlign: "justify",
    textJustify: "inter-word",
  },
}));

function replaceBr(input) {
  const data = input.split('<BR/>')
  const listItems = data.map((d) => <div>{d}</div>);

  return (
    <div>
      {listItems}
    </div>
  );
}

function formatExposition(input) {
  const data = input.split('<BR/>')
  const listItems = data.map((d) => <li>{d}</li>);

  return (
    <div>
      {listItems}
    </div>
  );
}

const Bio = props => {
  const { data, image, className, ...rest } = props;
  const classes = useStyles();
  console.log(data);
  return (
    <div className={classes.root}>

      <Grid container >
        <Grid item xs={12} sm={12} md={5} >

          <Img
            fluid={image}
            className={classes.image}
            lazyProps={{ width: '50%', height: '50%' }}
          />


        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <div className={classes.nom}>{data.nom}</div>

          <div className={classes.bio}>{replaceBr(data.bio)}</div>
          <div className={classes.exposition}>EXPOSITIONS</div>
          <div className={classes.bio}>{formatExposition(data.expositions)}</div>
        </Grid>

      </Grid>
    </div>
  );
};

export default Bio;
